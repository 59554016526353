::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: #1F323E;
}

::-webkit-scrollbar-thumb {
  background-color: #588888;
  border-radius: 20px;
}